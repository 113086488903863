import React from "react";
import Nav from "../../components/Nav/Nav";
import Footer from "../../components/Footer/Footer";
import AllServices from "../../components/AllServices/AllServices";
import Book from "../../components/Book/Book";

const OurServices = () => {
  return (
    <>
      <Nav tab={"Services"}/>
      <section class="page-header">
        <div class="page-header-shape"></div>
        <div class="container">
          <div class="page-header-info">
            <h4>Our Services!</h4>
            <h2>
              Start your journey with <br />
              <span>Ridek Taxi</span> Company!
            </h2>
            <p>
              Everything your taxi business <br />
              needs is already here!{" "}
            </p>
          </div>
        </div>
      </section>
      <AllServices/>
      <Book/>
      <Footer />
    </>
  );
};

export default OurServices;
