import React from "react";
import { Outlet, Link } from "react-router-dom";
{
  /* <li>
            <Link to="/" className={home ? styles.active : null}>
              Home
            </Link>
          </li> */
}

const Nav = ({ tab }) => {
  let isActiveHome = false;
  let isActiveServices = false;
  let isActiveAbout = false;
  let isActiveContact = false;

  if (tab === "Home") {
    isActiveHome = true;

    isActiveAbout = false;
    isActiveContact = false;
    isActiveServices = false;
  } else if (tab === "Services") {
    isActiveServices = true;

    isActiveAbout = false;
    isActiveContact = false;
    isActiveHome = false;
  } else if (tab === "About") {
    isActiveAbout = true;

    isActiveContact = false;
    isActiveHome = false;
    isActiveServices = false;
  } else if (tab === "Contact") {
    isActiveContact = true;

    isActiveAbout = false;
    isActiveServices = false;
    isActiveHome = false;
  } else {
    isActiveHome = true;

    isActiveAbout = false;
    isActiveContact = false;
    isActiveServices = false;
  }

  return (
    <>
      <div class="site-preloader">
        <div class="car">
          <div class="strike"></div>
          <div class="strike strike2"></div>
          <div class="strike strike3"></div>
          <div class="strike strike4"></div>
          <div class="strike strike5"></div>
          <div class="car-detail spoiler"></div>
          <div class="car-detail back"></div>
          <div class="car-detail center"></div>
          <div class="car-detail center1"></div>
          <div class="car-detail front"></div>
          <div class="car-detail wheel"></div>
          <div class="car-detail wheel wheel2"></div>
        </div>
      </div>

      <header class="main-header">
        <div class="top-header">
          <div class="container">
            <div class="top-header-wrap">
              <div class="top-left">
                <p>Reliable Taxi Service & Transport Solutions!</p>
              </div>
              <div class="top-right">
                <ul class="top-header-nav">
                  <li>
                    <a href="">Help</a>
                  </li>
                  <li>
                    <a href="">Support</a>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                    {/* <a href="faqs.html">FAQ</a> */}
                  </li>
                </ul>
                <ul class="header-social-share">
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=100076174063530&mibextid=LQQJ4d">
                    <i class="bi bi-facebook"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">
                    <i class="bi bi-twitter-x"></i>
                    </a>
                  </li> */}
                  <li>
                    <a href="https://www.instagram.com/horizondispatchmanagement?igsh=MmU4ZmZrdDVvdWQy">
                    <i class="bi bi-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                    <i class="bi bi-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="mid-header">
          <div class="container">
            <div class="mid-header-wrap">
              <div class="site-logo">
                <a href="index.html">
                  <img
                    src="https://html.dynamiclayers.net/dl/ridek/assets/img/logo-dark.png"
                    alt="Logo"
                  />
                </a>
              </div>
              <ul class="header-info">
                <li>
                  <div class="header-info-icon">
                  <i class="bi bi-telephone"></i>
                  </div>
                  <div class="header-info-text">
                    <h3>
                      <span>Call us now</span>
                      <a href="tel:5267214392"> +1 604 200 0141</a>
                    </h3>
                  </div>
                </li>
                <li>
                  <div class="header-info-icon">
                  <i class="bi bi-envelope"></i>
                  </div>
                  <div class="header-info-text">
                    <h3>
                      <span>Email now</span>horizondispatchmanagement@gmail.com
                    </h3>
                  </div>
                </li>
                <li>
                  <div class="header-info-icon">
                  <i class="bi bi-geo-alt-fill"></i>
                  </div>
                  <div class="header-info-text">
                    <h3>
                      <span>5 berkley place west. </span>Lethbridge alberta
                    </h3>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="nav-menu-wrapper">
          <div class="container">
            <div class="nav-menu-inner">
              <div class="site-logo">
                <a href="index.html">
                  <img
                    src="https://html.dynamiclayers.net/dl/ridek/assets/img/logo-dark.png"
                    alt="Logo"
                  />
                </a>
              </div>
              <div class="header-menu-wrap">
                <ul class="nav-menu">
                  <li class={`${isActiveHome?"active":""} dropdown_menu`}>
                    <Link to="/">
                      Home
                      {/* <a href="index.html">Home</a> */}
                    </Link>
                    {/* <ul>
                      <li>
                        <a href="index.html">Home Default</a>
                      </li>
                      <li>
                        <a href="index-2.html">Home Modern</a>
                      </li>
                    </ul> */}
                  </li>
                  {/* <li class="dropdown_menu">
                    <a href="about-us.html">Company</a>
                    <ul>
                      <li>
                        <a href="about-us.html">About Us</a>
                      </li>
                      <li>
                        <a href="about-company.html">About Company</a>
                      </li>
                      <li>
                        <a href="our-services.html">Our Services</a>
                      </li>
                      <li>
                        <a href="service-details.html">Services Details</a>
                      </li>
                      <li>
                        <a href="book-taxi.html">Book a Ride</a>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown_menu">
                    <a href="our-taxi.html">Our Taxi</a>
                    <ul>
                      <li>
                        <a href="our-taxi.html">Taxi Lists</a>
                      </li>
                      <li>
                        <a href="taxi-details.html">Taxi Details</a>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown_menu">
                    <a href="#">Pages</a>
                    <ul>
                      <li>
                        <a href="our-drivers.html">Our Drivers</a>
                      </li>
                      <li>
                        <a href="driver-details.html">Driver Details</a>
                      </li>
                      <li>
                        <a href="testimonials.html">Customer Reviews</a>
                      </li>
                      <li>
                        <a href="faqs.html">Help &amp; Faq's</a>
                      </li>
                      <li>
                        <a href="404.html">404 Error</a>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown_menu">
                    <a href="blog-grid.html">Blog</a>
                    <ul>
                      <li>
                        <a href="blog-grid.html">Grid Posts</a>
                      </li>
                      <li>
                        <a href="blog-classic.html">Classic Posts</a>
                      </li>
                      <li>
                        <a href="blog-details.html">Blog Details</a>
                      </li>
                    </ul>
                  </li> */}
                  <li class={`${isActiveServices?"active":""} dropdown_menu`}>
                    <Link to="/services">Our Services</Link>
                    {/* <a href="contact.html">Our Services</a> */}
                  </li>
                  <li class={`${isActiveAbout?"active":""} dropdown_menu`}>
                    <Link to="/about">About Us</Link>
                    {/* <a href="contact.html">About Us</a> */}
                  </li>
                  <li class={`${isActiveContact?"active":""} dropdown_menu`}>
                    <Link to="/contact">Contact</Link>
                    {/* <a href="contact.html">Contact</a> */}
                  </li>
                </ul>
              </div>
              <div class="menu-right-item">
                <div class="search-icon dl-search-icon">
                  <i class="bi bi-search"></i>
                </div>
                <div class="sidebox-icon dl-sidebox-icon">
                  <i class="bi bi-list"></i>
                </div>
                <a href="book-taxi.html" class="menu-btn">
                  Get a Qoute
                </a>
              </div>
              <div class="mobile-menu-icon">
                <div class="burger-menu">
                  <div class="line-menu line-half first-line"></div>
                  <div class="line-menu"></div>
                  <div class="line-menu line-half last-line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div id="popup-search-box">
        <div class="box-inner-wrap d-flex align-items-center">
          <form id="form" action="#" method="get" role="search">
            <input
              id="popup-search"
              type="text"
              name="s"
              placeholder="Type keywords here..."
            />
            <button id="popup-search-button" type="submit" name="submit">
              <i class="las la-search"></i>
            </button>
          </form>
          <div class="search-close">
            <i class="las la-times"></i>
          </div>
        </div>
      </div>
      <div id="searchbox-overlay"></div>
      <div id="popup-sidebox" class="popup-sidebox">
        <div class="sidebox-content">
          <div class="site-logo">
            <a href="index.html">
              <img src="assets/img/logo-light.png" alt="logo" />
            </a>
          </div>
          <p>
            Everything your taxi business needs is already here! Ridek, a theme
            made for taxi service companies.
          </p>
          <ul class="sidebox-list">
            <li class="call">
              <span>Call for ride:</span> +1 604 200 0141
            </li>
            <li>
              <span>You can find us at:</span>5 berkley place west. Lethbridge alberta
            </li>
            <li>
              <span>Email now:</span>horizondispatchmanagement@gmail.com

            </li>
          </ul>
        </div>
      </div>
      <div id="sidebox-overlay"></div>
    </>
  );
};

export default Nav;
