import React from "react";

const Footer = () => {
  return (
    <>
      <footer class="footer-section">
        <div class="footer-top-wrap bg-grey">
          <div class="container">
            <div class="footer-top">
              <div class="row align-items-end">
                <div class="col-lg-8">
                  <div class="brand">
                    <a class="footer-logo" href="index.html">
                      <img
                        src="https://html.dynamiclayers.net/dl/ridek/assets/img/logo-light.png"
                        alt="logo"
                      />
                    </a>
                    <p>
                      We successfully cope with tasks of varying complexity,
                      provide long-term guarantees and regularly master new
                      technologies.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="footer-call">
                  <i class="bi bi-telephone-forward-fill"></i>
                    <p>
                      <span>Call Now</span>
                      <a href="tel:5267214392"> +1 604 200 0141</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-mid-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-6 sm-padding">
                <div class="footer-item">
                  <div class="widget-title">
                    <h3>Working Hours</h3>
                  </div>
                  <ul class="footer-contact">
                    <li>
                      <span>Monday - Friday:</span>9.00am To 8.00pm
                    </li>
                    <li>
                      <span>Saturday:</span>10.00am To 7.30pm
                    </li>
                    <li>
                      <span>Sunday:</span>Close Day!
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 sm-padding">
                <div class="footer-item footer-list">
                  <div class="widget-title">
                    <h3>Usefull Links</h3>
                  </div>
                  <ul class="footer-links">
                    <li>
                      <a href="book-taxi.html">Get Qoute</a>
                    </li>
                    <li>
                      <a href="faqs.html">Help Center</a>
                    </li>
                    <li>
                      <a href="about-us.html">Privacy and Policy</a>
                    </li>
                    <li>
                      <a href="about-company.html">Terms of Use</a>
                    </li>
                    <li>
                      <a href="contact.html">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 sm-padding">
                <div class="footer-item">
                  <div class="widget-title">
                    <h3>Head Office</h3>
                  </div>
                  <ul class="footer-contact">
                    <li>
                      <span>Location:</span>5 berkley place west. Lethbridge alberta

                    </li>
                    <li>
                      <span>Join Us:</span>horizondispatchmanagement@gmail.com

                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="running-taxi">
            <div class="taxi"></div>
            <div class="taxi-2"></div>
            <div class="taxi-3"></div>
          </div>
        </div>
        <div class="copyright-wrap">
          <div class="container">
            <p>
              © <span id="currentYear"></span> Digiunction All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>

      <div id="scrollup">
        <button id="scroll-top" class="scroll-to-top">
        <i class="bi bi-arrow-up-circle-fill"></i>
        </button>
      </div>

      <div class="dl-cursor">
        <div class="cursor-icon-holder">
          <i class="las la-times"></i>
        </div>
      </div>
    </>
  );
};

export default Footer;
