import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";
import OurServices from "./pages/OurServices/OurServices";
import Faq from "./pages/Faq/Faq";
import ServiceDetails from "./pages/ServiceDetails/ServiceDetails";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/services" element={<OurServices />}></Route>
        <Route path="/faq" element={<Faq />}></Route>
        <Route path="/services/details" element={<ServiceDetails />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
