import React from 'react'
import Nav from '../../components/Nav/Nav'
import Footer from '../../components/Footer/Footer'
import AboutUs from '../../components/AboutUs/AboutUs'
import Services from '../../components/Services/Services'
import Testimonial from '../../components/Testimonial/Testimonial'
import Call from '../../components/Call/Call'


const About = () => {
  return (
    <>
    <Nav tab={"About"}/>
    <section class="page-header">
        <div class="page-header-shape"></div>
        <div class="container">
            <div class="page-header-info">
                <h4>About Us!</h4>
                <h2>Feel your journey <br/> with <span>Ridek!</span></h2>
                <p>Everything your taxi business <br/>needs is already here! </p>
            </div>
        </div>
    </section>
    <AboutUs/>
    <Services/>
    <Testimonial/>
    <Call/>
    <Footer/>
    </>
  )
}

export default About