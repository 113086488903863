import React from 'react'
import Nav from '../Nav/Nav'
import Footer from '../Footer/Footer'

const AboutUs = () => {
  return (
    <>
          <section class="about-section padding">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="about-img">
                <img
                  class="about-img1 wow fade-in-left"
                  data-wow-delay="200ms"
                  // src="https://html.dynamiclayers.net/dl/ridek/assets/img/about-1.png"
                  src='/images/about-1.jpg'
                  alt="img"
                />
                <img
                  class="about-img2 wow fade-in-bottom"
                  data-wow-delay="400ms"
                  // src="https://html.dynamiclayers.net/dl/ridek/assets/img/about-2.png"
                  src='/images/about-2.jpg'
                  alt="img"
                />
                <figure class="round-text" style={{width:"120px"}}>
                  <img
                    src="https://html.dynamiclayers.net/dl/ridek/assets/img/experience-text-round.png"
                    alt="img"
                  />
                </figure>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="section-heading mb-40 wow fade-in-right"
                data-wow-delay="200ms"
              >
                <h4>
                  <span></span>About Our Company
                </h4>
                <h2>
                  Feel the difference and Relaxation with Ridek Taxi Company!
                </h2>
                <p>
                At Horizon Management Solution, we understand the importance of streamlined business operations for the success of your organization. That's why we offer comprehensive business process outsourcing solutions tailored to your needs. With our expertise, advanced technology, and dedicated team, we help businesses across industries optimize their processes, improve efficiency, and reduce costs.                </p>
              </div>
              <ul class="about-info wow fade-in-right" data-wow-delay="400ms">
                <li>
                  <img
                    class="owner-thumb"
                    src="https://html.dynamiclayers.net/dl/ridek/assets/img/comment-1.png"
                    alt="thumb"
                  />
                  <div class="owner">
                    <h4>Founder - CEO</h4>
                    <img
                      class="signature"
                      src="https://html.dynamiclayers.net/dl/ridek/assets/img/signature.png"
                      alt="signature"
                    />
                  </div>
                </li>
                <li>
                  <h2>
                    <span>Call Now</span>
                    <a href="tel:5267214392"> +1 604 200 0141</a>
                  </h2>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutUs