import React from "react";
import Nav from "../../components/Nav/Nav";
import Footer from "../../components/Footer/Footer";
import AboutUs from "../../components/AboutUs/AboutUs";
import Services from "../../components/Services/Services";
import Testimonial from "../../components/Testimonial/Testimonial";
import Call from "../../components/Call/Call";

const Home = () => {
  return (
    <>
      <Nav tab={"Home"}/>

      <div class="slider-section">
        <div class="main-slider">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="slider-img">
                <img
                  src="https://html.dynamiclayers.net/dl/ridek/assets/img/slider-bg.jpg"
                  alt="img"
                  class="kenburns"
                  style={{ animationDuration: "12000ms" }}
                />
              </div>
              <div class="car-holder">
                {/* <img
                  src="https://html.dynamiclayers.net/dl/ridek/assets/img/car-1.png"
                  alt="car"
                  data-animation="car-animation"
                  data-duration="1.5s"
                /> */}
                <div class="shape">
                  <div
                    data-animation="slide-in-bottom"
                    data-duration="1.5s"
                    data-delay="0.3s"
                  ></div>
                  <div
                    data-animation="fade-in"
                    data-duration="1.5s"
                    data-delay="0.2s"
                  ></div>
                  <div
                    data-animation="slide-in-top"
                    data-duration="1.5s"
                    data-delay="0.5s"
                  ></div>
                </div>
              </div>
              <div class="slider-content-wrap d-flex align-items-center text-left">
                <div class="container">
                  <div class="slider-content">
                    <div class="slider-caption medium">
                      <div class="inner-layer">
                        <div data-animation="fade-in-bottom" data-delay="0.3s">
                          Travel securely with us!
                        </div>
                      </div>
                    </div>
                    <div class="slider-caption big">
                      <div class="inner-layer">
                        <div data-animation="fade-in-bottom" data-delay="0.5s">
                          Get your best qoute from
                          <br />
                          anywhare today!
                        </div>
                      </div>
                    </div>
                    <div class="slider-caption small">
                      <div class="inner-layer">
                        <div
                          data-animation="fade-in-bottom"
                          data-delay="0.7s"
                          data-duration="1s"
                        >
                          Everything your taxi business needs is already here!{" "}
                          <br />
                          Ridek made for taxi service companies!
                        </div>
                      </div>
                    </div>
                    <div class="slider-btn">
                      <a
                        href="book-taxi.html"
                        class="default-btn"
                        data-animation="fade-in-bottom"
                        data-delay="0.9s"
                      >
                        Get Qoute Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="slider-img">
                <img
                  src="https://html.dynamiclayers.net/dl/ridek/assets/img/slider-bg.jpg"
                  alt="img"
                  class="kenburns"
                  style={{ animationDuration: "12000ms" }}
                />
              </div>
              <div class="car-holder">
                {/* <img
                  src="https://html.dynamiclayers.net/dl/ridek/assets/img/car-1.png"
                  alt="car"
                  data-animation="car-animation"
                  data-duration="1.5s"
                /> */}
                <div class="shape">
                  <div
                    data-animation="slide-in-bottom"
                    data-duration="1.5s"
                    data-delay="0.3s"
                  ></div>
                  <div
                    data-animation="fade-in"
                    data-duration="1.5s"
                    data-delay="0.2s"
                  ></div>
                  <div
                    data-animation="slide-in-top"
                    data-duration="1.5s"
                    data-delay="0.5s"
                  ></div>
                </div>
              </div>
              <div class="slider-content-wrap d-flex align-items-center text-left">
                <div class="container">
                  <div class="slider-content">
                    <div class="slider-caption medium">
                      <div class="inner-layer">
                        <div data-animation="fade-in-bottom" data-delay="0.3s">
                          Travel securely with us!
                        </div>
                      </div>
                    </div>
                    <div class="slider-caption big">
                      <div class="inner-layer">
                        <div data-animation="fade-in-bottom" data-delay="0.5s">
                        Get your best qoute from
                          <br />
                          anywhare today!
                        </div>
                      </div>
                    </div>
                    <div class="slider-caption small">
                      <div class="inner-layer">
                        <div
                          data-animation="fade-in-bottom"
                          data-delay="0.7s"
                          data-duration="1s"
                        >
                          Everything your taxi business needs is already here!{" "}
                          <br />
                          Ridek made for taxi service companies!
                        </div>
                      </div>
                    </div>
                    <div class="slider-btn">
                      <a
                        href="book-taxi.html"
                        class="default-btn"
                        data-animation="fade-in-bottom"
                        data-delay="0.9s"
                      >
                        Get Qoute Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="slider-controls">
            <div class="slider-control slider-button-prev">
              <span>
              <i class="bi bi-arrow-left"></i>
              </span>
              Prev
            </div>
            <div class="slider-pagination"></div>
            <div class="slider-control slider-button-next">
              Next
              <span>
              <i class="bi bi-arrow-right"></i>
              </span>
            </div>
          </div>
        </div>
      </div>

      <AboutUs/>

     <Services/>

      {/* <section class="booking-section">
        <div class="container">
          <div class="row pos-relative padding">
            <div class="col-lg-4">
              <div
                class="booking-car wow fade-in-left"
                data-wow-delay="200ms"
              ></div>
            </div>
            <div class="col-lg-8">
              <div class="booking-wrap">
                <div class="section-heading mb-30">
                  <h4>
                    <span></span>Online Booking
                  </h4>
                  <h2 class="white">Get your best qoute</h2>
                </div>
                <form action="book-ride.php" id="book-taxi-ride">
                  <div class="booking-form">
                    <div class="form-field">
                    <i class="bi bi-person-fill"></i>
                      <input
                        type="text"
                        id="full-name"
                        name="full-name"
                        class="form-control"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div class="form-field">
                    <i class="bi bi-envelope-fill"></i>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        class="form-control"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div class="form-field">
                    <i class="bi bi-tags-fill"></i>
                      <select name="package-type" id="type" class="niceSelect">
                        <option value="standard">Standard</option>
                        <option value="business">Business</option>
                        <option value="economy">Economy</option>
                        <option value="vip-spacial">VIP Spacial</option>
                        <option value="comfort">Comfort</option>
                      </select>
                    </div>
                    <div class="form-field">
                    <i class="bi bi-person-vcard-fill"></i>
                      <select
                        name="Details-4"
                        id="Details-4"
                        class="niceSelect"
                      >
                        <option value="1">1 Person</option>
                        <option value="2">2 Person</option>
                        <option value="3">3 Person</option>
                        <option value="4">info</option>
                        <option value="5">5 Person</option>
                      </select>
                    </div>
                    <div class="form-field">
                    <i class="bi bi-pin-map-fill"></i>
                      <input
                        type="text"
                        id="start-dest"
                        name="start-dest"
                        class="form-control"
                        placeholder="Start Destination"
                        required
                      />
                    </div>
                    <div class="form-field">
                    <i class="bi bi-pin-map-fill"></i>
                      <input
                        type="text"
                        id="end-dest"
                        name="end-dest"
                        class="form-control"
                        placeholder="End Destination"
                        required
                      />
                    </div>
                    <div class="form-field">
                    <i class="bi bi-calendar-date-fill"></i>
                      <input
                        type="text"
                        id="ride-date"
                        name="ride-date"
                        class="form-control date-picker"
                        placeholder="Select Date"
                        required
                      />
                    </div>
                    <div class="form-field">
                    <i class="bi bi-alarm-fill"></i>
                      <input
                        type="text"
                        id="ride-time"
                        name="ride-time"
                        class="form-control time-picker"
                        placeholder="Select Time"
                        required
                      />
                    </div>
                    <div class="form-field">
                      <button id="submit" class="default-btn" type="submit">
                        Get Your Qoute
                      </button>
                    </div>
                  </div>
                  <div id="form-messages" class="alert" role="alert"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section class="pricing-section bg-grey bd-bottom padding">
        <div class="container">
          <div
            class="section-heading text-center mb-40 wow fade-in-bottom"
            data-wow-delay="200ms"
          >
            <h4>
              <span></span>Lets Go With Us!
            </h4>
            <h2>Our Benifits</h2>
            <p>
              We successfully cope with tasks of varying complexity, provide
              long-term <br />
              guarantees and regularly master new technologies.
            </p>
          </div>
          <ul class="nav pricing-tab-menu" id="pricing-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="hybrid-taxi"
                data-bs-toggle="tab"
                data-bs-target="#hybrid-taxi-content"
                type="button"
                role="tab"
                aria-controls="hybrid-taxi-content"
                aria-selected="true"
              >
                24/7 Customer Support
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="town-taxt"
                data-bs-toggle="tab"
                data-bs-target="#town-taxi-content"
                type="button"
                role="tab"
                aria-controls="town-taxi-content"
                aria-selected="false"
              >
                Real-time Driver Connection
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="limousine-taxi"
                data-bs-toggle="tab"
                data-bs-target="#limousine-taxi-content"
                type="button"
                role="tab"
                aria-controls="limousine-taxi-content"
                aria-selected="false"
              >
                Efficient Booking
              </button>
            </li>
          </ul>

          <div class="tab-content" id="pricing-tab-content">
            <div
              class="tab-pane fade show active"
              id="hybrid-taxi-content"
              role="tabpanel"
              aria-labelledby="hybrid-taxi-content"
            >
              <div class="row">
                <div class="col-lg-4 col-md-6 sm-padding">
                  <div class="pricing-item">
                    <div class="pricing-head-wrap">
                      <div class="pricing-car">
                        <img
                          src="https://html.dynamiclayers.net/dl/ridek/assets/img/pricing-car.png"
                          alt="car"
                        />
                        <div class="price">information</div>
                      </div>
                    </div>
                    <div class="pricing-head">
                      <h3>
                        <a href="taxi-details.html">Details</a>
                      </h3>
                      <span class="location">Chicago</span>
                    </div>
                    <ul class="pricing-list">
                      <li>
                        Details-1: <span>info</span>
                      </li>
                      <li>
                        Details-2: <span>info</span>
                      </li>
                      <li>
                        Details-3: <span>info</span>
                      </li>
                      <li>
                        Details-4: <span>info</span>
                      </li>
                      <li>
                        <a href="book-taxi.html" class="default-btn">
                          Get Qoute Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 sm-padding">
                  <div class="pricing-item">
                    <div class="pricing-head-wrap">
                      <div class="pricing-car">
                        <img
                          src="https://html.dynamiclayers.net/dl/ridek/assets/img/pricing-car.png"
                          alt="car"
                        />
                        <div class="price">information</div>
                      </div>
                    </div>
                    <div class="pricing-head">
                      <h3>
                        <a href="taxi-details.html">Detailsz</a>
                      </h3>
                      <span class="location">Florida</span>
                    </div>
                    <ul class="pricing-list">
                      <li>
                        Details-1: <span>info</span>
                      </li>
                      <li>
                        Details-2: <span>info</span>
                      </li>
                      <li>
                        Details-3: <span>info</span>
                      </li>
                      <li>
                        Details-4: <span>info</span>
                      </li>
                      <li>
                        <a href="book-taxi.html" class="default-btn">
                          Get Qoute Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 sm-padding">
                  <div class="pricing-item">
                    <div class="pricing-head-wrap">
                      <div class="pricing-car">
                        <img
                          src="https://html.dynamiclayers.net/dl/ridek/assets/img/pricing-car.png"
                          alt="car"
                        />
                        <div class="price">information</div>
                      </div>
                    </div>
                    <div class="pricing-head">
                      <h3>
                        <a href="taxi-details.html">Details</a>
                      </h3>
                      <span class="location">New York</span>
                    </div>
                    <ul class="pricing-list">
                      <li>
                        Details-1: <span>info</span>
                      </li>
                      <li>
                        Details-2: <span>info</span>
                      </li>
                      <li>
                        Details-3: <span>info</span>
                      </li>
                      <li>
                        Details-4: <span>info</span>
                      </li>
                      <li>
                        <a href="book-taxi.html" class="default-btn">
                          Get Qoute Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="town-taxi-content"
              role="tabpanel"
              aria-labelledby="town-taxi-content"
            >
              <div class="row">
                <div class="col-lg-4 col-md-6 sm-padding">
                  <div class="pricing-item">
                    <div class="pricing-head-wrap">
                      <div class="pricing-car">
                        <img
                          src="https://html.dynamiclayers.net/dl/ridek/assets/img/pricing-car.png"
                          alt="car"
                        />
                        <div class="price">information</div>
                      </div>
                    </div>
                    <div class="pricing-head">
                      <h3>
                        <a href="taxi-details.html">Details</a>
                      </h3>
                      <span class="location">Chicago</span>
                    </div>
                    <ul class="pricing-list">
                      <li>
                        Details-1: <span>info</span>
                      </li>
                      <li>
                        Details-2: <span>info</span>
                      </li>
                      <li>
                        Details-3: <span>info</span>
                      </li>
                      <li>
                        Details-4: <span>info</span>
                      </li>
                      <li>
                        <a href="book-taxi.html" class="default-btn">
                          Get Qoute Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 sm-padding">
                  <div class="pricing-item">
                    <div class="pricing-head-wrap">
                      <div class="pricing-car">
                        <img
                          src="https://html.dynamiclayers.net/dl/ridek/assets/img/pricing-car.png"
                          alt="car"
                        />
                        <div class="price">information</div>
                      </div>
                    </div>
                    <div class="pricing-head">
                      <h3>
                        <a href="taxi-details.html">Detailsz</a>
                      </h3>
                      <span class="location">Florida</span>
                    </div>
                    <ul class="pricing-list">
                      <li>
                        Details-1: <span>info</span>
                      </li>
                      <li>
                        Details-2: <span>info</span>
                      </li>
                      <li>
                        Details-3: <span>info</span>
                      </li>
                      <li>
                        Details-4: <span>info</span>
                      </li>
                      <li>
                        <a href="book-taxi.html" class="default-btn">
                          Get Qoute Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 sm-padding">
                  <div class="pricing-item">
                    <div class="pricing-head-wrap">
                      <div class="pricing-car">
                        <img
                          src="https://html.dynamiclayers.net/dl/ridek/assets/img/pricing-car.png"
                          alt="car"
                        />
                        <div class="price">information</div>
                      </div>
                    </div>
                    <div class="pricing-head">
                      <h3>
                        <a href="taxi-details.html">Details</a>
                      </h3>
                      <span class="location">New York</span>
                    </div>
                    <ul class="pricing-list">
                      <li>
                        Details-1: <span>info</span>
                      </li>
                      <li>
                        Details-2: <span>info</span>
                      </li>
                      <li>
                        Details-3: <span>info</span>
                      </li>
                      <li>
                        Details-4: <span>info</span>
                      </li>
                      <li>
                        <a href="book-taxi.html" class="default-btn">
                          Get Qoute Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="limousine-taxi-content"
              role="tabpanel"
              aria-labelledby="limousine-taxi"
            >
              <div class="row">
                <div class="col-lg-4 col-md-6 sm-padding">
                  <div class="pricing-item">
                    <div class="pricing-head-wrap">
                      <div class="pricing-car">
                        <img
                          src="https://html.dynamiclayers.net/dl/ridek/assets/img/pricing-car.png"
                          alt="car"
                        />
                        <div class="price">information</div>
                      </div>
                    </div>
                    <div class="pricing-head">
                      <h3>
                        <a href="taxi-details.html">Details</a>
                      </h3>
                      <span class="location">Chicago</span>
                    </div>
                    <ul class="pricing-list">
                      <li>
                        Details-1: <span>info</span>
                      </li>
                      <li>
                        Details-2: <span>info</span>
                      </li>
                      <li>
                        Details-3: <span>info</span>
                      </li>
                      <li>
                        Details-4: <span>info</span>
                      </li>
                      <li>
                        <a href="book-taxi.html" class="default-btn">
                          Get Qoute Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 sm-padding">
                  <div class="pricing-item">
                    <div class="pricing-head-wrap">
                      <div class="pricing-car">
                        <img
                          src="https://html.dynamiclayers.net/dl/ridek/assets/img/pricing-car.png"
                          alt="car"
                        />
                        <div class="price">information</div>
                      </div>
                    </div>
                    <div class="pricing-head">
                      <h3>
                        <a href="taxi-details.html">Detailsz</a>
                      </h3>
                      <span class="location">Florida</span>
                    </div>
                    <ul class="pricing-list">
                      <li>
                        Details-1: <span>info</span>
                      </li>
                      <li>
                        Details-2: <span>info</span>
                      </li>
                      <li>
                        Details-3: <span>info</span>
                      </li>
                      <li>
                        Details-4: <span>info</span>
                      </li>
                      <li>
                        <a href="book-taxi.html" class="default-btn">
                          Get Qoute Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 sm-padding">
                  <div class="pricing-item">
                    <div class="pricing-head-wrap">
                      <div class="pricing-car">
                        <img
                          src="https://html.dynamiclayers.net/dl/ridek/assets/img/pricing-car.png"
                          alt="car"
                        />
                        <div class="price">information</div>
                      </div>
                    </div>
                    <div class="pricing-head">
                      <h3>
                        <a href="taxi-details.html">Details</a>
                      </h3>
                      <span class="location">New York</span>
                    </div>
                    <ul class="pricing-list">
                      <li>
                        Details-1: <span>info</span>
                      </li>
                      <li>
                        Details-2: <span>info</span>
                      </li>
                      <li>
                        Details-3: <span>info</span>
                      </li>
                      <li>
                        Details-4: <span>info</span>
                      </li>
                      <li>
                        <a href="book-taxi.html" class="default-btn">
                          Get Qoute Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="team-section padding">
        <div class="container">
            <div class="section-heading text-center mb-40">
                <h4><span></span>Lets Go With Us!</h4>
                <h2>Our Team</h2>
                <p>We successfully cope with tasks of varying complexity, provide long-term <br/>guarantees and regularly master new technologies.</p>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 sm-padding">
                    <div class="team-item">
                        <div class="team-thumb">
                            <img src="https://html.dynamiclayers.net/dl/ridek/assets/img/team-1.jpg" alt="thumb"/>
                        </div>
                        <div class="team-content">
                            <h3><a href="driver-details.html">Félix Lengyel</a></h3>
                            <h4>BMW X5 2008</h4>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 sm-padding">
                    <div class="team-item">
                        <div class="team-thumb">
                            <img src="https://html.dynamiclayers.net/dl/ridek/assets/img/team-2.jpg" alt="thumb"/>
                        </div>
                        <div class="team-content">
                            <h3><a href="driver-details.html">Henry Nathan</a></h3>
                            <h4>Mercedes‑Benz</h4>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 sm-padding">
                    <div class="team-item">
                        <div class="team-thumb">
                            <img src="https://html.dynamiclayers.net/dl/ridek/assets/img/team-3.jpg" alt="thumb"/>
                        </div>
                        <div class="team-content">
                            <h3><a href="driver-details.html">Brandon Larned</a></h3>
                            <h4>Hyundai 2022</h4>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 sm-padding">
                    <div class="team-item">
                        <div class="team-thumb">
                            <img src="https://html.dynamiclayers.net/dl/ridek/assets/img/team-4.jpg" alt="thumb"/>
                        </div>
                        <div class="team-content">
                            <h3><a href="driver-details.html">Sasha Hostyn</a></h3>
                            <h4>Toyota XCorola</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

      <Testimonial/>

      <Call/>

      <Footer />
    </>
  );
};

export default Home;
