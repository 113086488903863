import React from 'react'
import Nav from '../../components/Nav/Nav'
import Footer from '../../components/Footer/Footer'

const ServiceDetails = () => {
  return (
    <>
    <Nav tab={"Services"}/>
    <section class="page-header">
        <div class="page-header-shape"></div>
        <div class="container">
            <div class="page-header-info">
                <h4>Service Details!</h4>
                <h2>Feel your journey <br/> with <span>Ridek!</span></h2>
                <p>Everything your taxi business <br/>needs is already here! </p>
            </div>
        </div>
    </section>
    <section class="service-details bg-grey padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 sm-padding">
                    <div class="service-details-info">
                        <img src="https://html.dynamiclayers.net/dl/ridek/assets/img/post-2.jpg" alt="img"/>
                        <h2>Comfortable Business Transport Services!</h2>
                        <p>Financial experts support or help you to to find out which way you can raise your funds more. Arkit a trusted name for providing assistants. Initially their main objective was to ensure the service they provide these people are loyal to their industry.</p>
                        <p>Unless you are the one who really cares about this, it is not terribly important. What all matters are how your hybrid mobile application development is going to work in the long run as no one will care about how it was built.</p>
                        <ul class="service-features">
                            <li>
                                <div><i class="las la-shipping-fast"></i></div>
                                <div>
                                    <h3>Fast &amp; Easy Pickups</h3>
                                    <p>Everything your taxi business needs is already here!</p>
                                </div>
                            </li>
                            <li>
                                <div><i class="las la-taxi"></i></div>
                                <div>
                                    <h3>Instant Car Provide</h3>
                                    <p>Everything your taxi business needs is already here!</p>
                                </div>
                            </li>
                            <li>
                                <div><i class="las la-tachometer-alt"></i></div>
                                <div>
                                    <h3>Save Expert Drive</h3>
                                    <p>Everything your taxi business needs is already here!</p>
                                </div>
                            </li>
                            <li>
                                <div><i class="las la-user-astronaut"></i></div>
                                <div>
                                    <h3>24/7 Support</h3>
                                    <p>Everything your taxi business needs is already here!</p>
                                </div>
                            </li>
                        </ul>
                        <p>There are some big shifts taking place in the field of construction equipment mathematics. Starting with the integration of mathematics devices in vehicles right from manufacturers, to the standardization and integration of mathematics data across various business functions, the future of mathematics.</p>
                        <div class="service-details-list">
                            <img src="https://html.dynamiclayers.net/dl/ridek/assets/img/post-1.jpg" alt="img"/>
                            <div>
                                <h3>Passenger Benefits!</h3>
                                <p>Everything your taxi business needs is already here!</p>
                                <ul class="service-icon-list">
                                    <li><i class="las la-hand-point-right"></i>Use the latest diagnostic equipment.</li>
                                    <li><i class="las la-hand-point-right"></i>Automotive service for our clients.</li>
                                    <li><i class="las la-hand-point-right"></i>Quick dedicated support team.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 sm-padding">
                    <div class="service-sidebar sidebar-widget">
                        <div class="widget-title service-widget">
                            <h3>Categories</h3>
                        </div>
                        <ul>
                            <li><a href="#">Regular Transport</a><i class="las la-arrow-right"></i></li>
                            <li><a href="#">City Transport</a><i class="las la-arrow-right"></i></li>
                            <li><a href="#">Airport Transport</a><i class="las la-arrow-right"></i></li>
                            <li><a href="#">Long Journey</a><i class="las la-arrow-right"></i></li>
                            <li><a href="#">Luggage Transport</a><i class="las la-arrow-right"></i></li>
                        </ul>
                    </div>
                    <div class="sidebar-widget banner">
                        <div class="banner-info">
                            <a href="index.html"><img class="logo" src="https://html.dynamiclayers.net/dl/ridek/assets/img/logo-light.png" alt="logo"/></a>
                            <h3>Feel your journey with <span>Ridek!</span></h3>
                            <p>Everything your taxi business needs is already here!</p>
                            <h2 class="discount"><span>-60%</span> Off Today!</h2>
                            <a href="contact.html" class="default-btn">Get Your Offer Now</a>
                        </div>
                    </div>
                    <div class="sidebar-widget">
                        <div class="widget-title">
                            <h3>Tags</h3>
                        </div>
                        <ul class="tags">
                            <li><a href="#">business</a></li>
                            <li><a href="#">marketing</a></li>
                            <li><a href="#">startup</a></li>
                            <li><a href="#">design</a></li>
                            <li><a href="#">consulting</a></li>
                            <li><a href="#">strategy</a></li>
                            <li><a href="#">development</a></li>
                            <li><a href="#">tips</a></li>
                            <li><a href="#">Seo</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer/>
    </>
  )
}

export default ServiceDetails