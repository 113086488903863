import React from 'react'

const Testimonial = () => {
  return (
    <section class="testimonial-section bg-grey">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="section-heading mb-10">
                <h4>
                  <span></span>Clients Testimonial
                </h4>
                <h2>Ridek Passenger Reviews...</h2>
                <p>
                  We successfully cope with tasks of varying complexity, provide
                  long-term guarantees and regularly master technologies.
                </p>
              </div>
              <div class="swiper-outside testi-pagination">
                <div class="testimonial-carousel">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <div class="testi-item">
                        <div class="quote-icon">
                        <i class="bi bi-chat-quote-fill"></i>
                        </div>
                        <p>
                          Good to have transportation available in places and
                          times it is otherwise hard to find, but the app has
                          always been a bit difficult to use especially how it
                          works or doesnt with large font phone settings.
                        </p>
                        <div class="testi-author">
                          <div class="author-thumb">
                            <img
                              src="https://html.dynamiclayers.net/dl/ridek/assets/img/comment-1.png"
                              alt="author"
                            />
                          </div>
                          <div class="author-info">
                            <h3>
                              Eredrik Johanson <span>Financial .INC</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-slide">
                      <div class="testi-item">
                        <div class="quote-icon">
                        <i class="bi bi-chat-quote-fill"></i>
                        </div>
                        <p>
                          Good to have transportation available in places and
                          times it is otherwise hard to find, but the app has
                          always been a bit difficult to use especially how it
                          works or doesnt with large font phone settings.
                        </p>
                        <div class="testi-author">
                          <div class="author-thumb">
                            <img
                              src="https://html.dynamiclayers.net/dl/ridek/assets/img/comment-1.png"
                              alt="author"
                            />
                          </div>
                          <div class="author-info">
                            <h3>
                              Eredrik Johanson <span>Financial .INC</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-slide">
                      <div class="testi-item">
                        <div class="quote-icon">
                        <i class="bi bi-chat-quote-fill"></i>
                        </div>
                        <p>
                          Good to have transportation available in places and
                          times it is otherwise hard to find, but the app has
                          always been a bit difficult to use especially how it
                          works or doesnt with large font phone settings.
                        </p>
                        <div class="testi-author">
                          <div class="author-thumb">
                            <img
                              src="https://html.dynamiclayers.net/dl/ridek/assets/img/comment-1.png"
                              alt="author"
                            />
                          </div>
                          <div class="author-info">
                            <h3>
                              Eredrik Johanson <span>Financial .INC</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature-wrap">
                <div
                  class="section-heading mb-30 wow fade-in-right"
                  data-wow-delay="200ms"
                >
                  <h4 class="white">
                    <span></span>Why Choose Us!
                  </h4>
                  <h2 class="white">Why Ride with Ridek?</h2>
                  <p class="white">
                    We successfully cope with tasks of varying complexity,
                    provide long-term guarantees and regularly master
                    technologies.
                  </p>
                </div>

                <ul class="ridek-features">
                  <li class="wow fade-in-right" data-wow-delay="300ms">
                    <div class="feature-icon">
                    <i class="bi bi-1-square-fill"></i>
                    </div>
                    <div class="feature-content">
                      <h3>Safe Guarantee</h3>
                      <p>
                        We successfully cope with tasks of <br /> varying
                        complexity!
                      </p>
                    </div>
                  </li>
                  <li class="wow fade-in-right" data-wow-delay="400ms">
                    <div class="feature-icon">
                    <i class="bi bi-2-square-fill"></i>
                    </div>
                    <div class="feature-content">
                      <h3>Fast Pickups</h3>
                      <p>
                        We successfully cope with tasks of <br /> varying
                        complexity!
                      </p>
                    </div>
                  </li>
                  <li class="wow fade-in-right" data-wow-delay="500ms">
                    <div class="feature-icon">
                    <i class="bi bi-3-square-fill"></i>
                    </div>
                    <div class="feature-content">
                      <h3>Quick Ride</h3>
                      <p>
                        We successfully cope with tasks of <br /> varying
                        complexity!
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Testimonial