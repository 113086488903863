import React from 'react'

const Call = () => {
  return (
    <section class="cta-section padding">
        <div class="cta-men wow fade-in-bottom" data-wow-delay="200ms"></div>
        <div class="container">
          <div class="cta-content">
            <h2>
              Call Us Now <span></span> <br /> For Your Next Move!
            </h2>
            <p>
              We successfully cope with tasks of varying complexity,
              <br />
              guarantees and regularly master new technologies.
            </p>
            <div class="cta-call">
            <i class="bi bi-telephone-forward-fill"></i>
              <p>
                <span>Call Now</span>
                <a href="tel:5267214392"> +1 604 200 0141</a>
              </p>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Call