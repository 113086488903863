import React from "react";
import Nav from "../../components/Nav/Nav";
import Footer from "../../components/Footer/Footer";

const Faq = () => {
  return (
    <>
      <Nav tab={"Home"}/>
      <section class="page-header">
        <div class="page-header-shape"></div>
        <div class="container">
          <div class="page-header-info">
            <h4>Help &amp; Faqs!</h4>
            <h2>
              Frequently Asked <br /> <span>Questions!</span>
            </h2>
            <p>
              Everything your taxi business <br />
              needs is already here!{" "}
            </p>
          </div>
        </div>
      </section>
      <section class="blog-section blog-page bg-grey padding">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 sm-padding">
              <div class="accordion faq-accordion" id="faq-accordion">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What makes a good taxi service?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faq-accordion"
                  >
                    <div class="accordion-body">
                      <p>
                        The restaurants in Hangzhou also catered to many
                        northern Chinese who had fled south from Kaifeng during
                        the Jurchen invasion of the 1120s, while it is also
                        known that many restaurants were run by families. The
                        restaurants in Hangzhou also catered to many northern
                        Chinese who had fled south from Kaifeng during the
                        Jurchen invasion.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      What is the purpose of a taxi service?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#faq-accordion"
                  >
                    <div class="accordion-body">
                      <p>
                        The restaurants in Hangzhou also catered to many
                        northern Chinese who had fled south from Kaifeng during
                        the Jurchen invasion of the 1120s, while it is also
                        known that many restaurants were run by families. The
                        restaurants in Hangzhou also catered to many northern
                        Chinese who had fled south from Kaifeng during the
                        Jurchen invasion.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {" "}
                      How to download the ridek taxi app online?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faq-accordion"
                  >
                    <div class="accordion-body">
                      <p>
                        The restaurants in Hangzhou also catered to many
                        northern Chinese who had fled south from Kaifeng during
                        the Jurchen invasion of the 1120s, while it is also
                        known that many restaurants were run by families. The
                        restaurants in Hangzhou also catered to many northern
                        Chinese who had fled south from Kaifeng during the
                        Jurchen invasion.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      {" "}
                      What Should I be Asking For first ride?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#faq-accordion"
                  >
                    <div class="accordion-body">
                      <p>
                        The restaurants in Hangzhou also catered to many
                        northern Chinese who had fled south from Kaifeng during
                        the Jurchen invasion of the 1120s, while it is also
                        known that many restaurants were run by families. The
                        restaurants in Hangzhou also catered to many northern
                        Chinese who had fled south from Kaifeng during the
                        Jurchen invasion.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFive">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      {" "}
                      How Many cars does ridek taxi service Have?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#faq-accordion"
                  >
                    <div class="accordion-body">
                      <p>
                        The restaurants in Hangzhou also catered to many
                        northern Chinese who had fled south from Kaifeng during
                        the Jurchen invasion of the 1120s, while it is also
                        known that many restaurants were run by families. The
                        restaurants in Hangzhou also catered to many northern
                        Chinese who had fled south from Kaifeng during the
                        Jurchen invasion.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Faq;
