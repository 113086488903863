import React from 'react'

const Book = () => {
  return (
    <section class="cta-section-2 padding">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="cta-content">
                        <h4>Benifits</h4>
                        <h2>Feel your journey with Ridek!</h2>
                        <a href="book-taxi.html" class="default-btn">Get a qoute</a>
                    </div>
                </div>
                <div class="col-lg-5">
                    <ul class="check-list">
                        <li><i class="bi bi-lightbulb-fill"></i>Easy payment systems.</li>
                        <li><i class="bi bi-lightbulb-fill"></i>Make safety a top priority.</li>
                        <li><i class="bi bi-lightbulb-fill"></i>First and quick pickups.</li>
                        <li><i class="bi bi-lightbulb-fill"></i>Access Ridek globally.</li>
                    </ul>
                </div>
                <div class="col-lg-3">
                    <div class="cta-men"></div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Book